import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'

import { createPinia } from 'pinia'
const pinia = createPinia()

const lightTheme = {
  dark: false,
  variables: {
    'overlay-opacity': 0.87,
    'theme-surface-variant': '#888888',
  }
}

const defaults = {
  VBtn: {
    class: 'text-capitalize',
    variant: 'flat'
  },
  VTextField: {
    flat: true,
  },
  VTextarea: {
    flat: true,
    variant: 'solo-filled',
    rounded: 'lg'
  }
}

const vuetify = createVuetify({
    components,
    directives,
    icons: {
      defaultSet: 'mdi',
      aliases,
      sets: {
        mdi,
      },
    },
    defaults: defaults,
    theme: {
      defaultTheme: 'lightTheme',
      themes: {
        lightTheme,
      },
    },
  })

createApp(App).use(router).use(vuetify).use(pinia).mount('#app')
