<template>
    <v-layout class="">
        <UnauthorizedAppBar />
        <v-main>
            <v-container>
               <h3 class="mb-10">Privacy Policy</h3>
               <v-sheet class="ml-3">
                   <p class="my-5">1. Introduction</p>
                   <p>Please read this Privacy Policy (“Privacy Policy”) before using our Service including the Website, Widget and API (as defined below), referred to collectively as the “Service”. This Privacy Policy governs the types of information and data we collect and how we use and share this information. Your access to and use of the Service are available for your use only on the condition that you agree to the Terms of Service available under the following address: https://app.mockin.work/tos (“Terms of Service”) which include the terms of the Privacy Policy set forth below. Exie App LLC (“Company”) operates the Service. We use your data to provide and improve Service. By using Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms of Service.</p>
           
                   <p class="my-5">2. Definitions</p>
                   <p>    Cookies are small files stored on your Device.</p>
           
                   <p>    Device means a computer or a mobile device.</p>
           
                   <p>    Data Controller means a natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal data are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your data.</p>
           
                   <p>    Data Processors (or Service Providers) means any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively.</p>
           
                   <p>    Data Subject is any living individual who is the subject of Personal Data.</p>
           
                   <p>    Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).</p>
           
                   <p>    Service means together the Website, Widget and/or the API (depending on the scope dedicated to a given User).</p>
           
                   <p>    Usage Data is data collected automatically either generated by the use of Service or from Service infrastructure itself (for example, the duration of a page visit).</p>
           
                   <p>    User is the individual using our Service. User corresponds to the Data Subject, who is the subject of Personal Data.</p>
           
                   <p>    Website means web pages located at mockin.work and it subdomains.</p>
           
                   <p class="my-5">    3. The Data Controller</p>
                   <p>    The controller of your Personal Data is: Exie App LLC.</p>
           
                   <p class="my-5">    4. Information Collection and Use</p>
                   <p>    We collect several different types of information for various purposes to provide and improve our Service to you.</p>
           
                   <p class="my-5">    5. Types of Data Collected</p>
                   <p class="my-5">    <b>Personal Data</b></p>
           
                   <p>    While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”), including:</p>
                       <ul class="ml-10">
                           <li>email address,</li>
                           <li>first name and last name,</li>
                           <li>Cookies and Usage Data.</li>
                           
                       </ul>
                   <p class="my-5">    <b>Usage Data</b></p>
           
                   <p>    We may also collect information that your browser sends whenever you visit our Service or when you access Service by or through a Device (“Usage Data”). This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique Device identifiers and other diagnostic data. When you access Service with a Device, this Usage Data may include information such as the type of Device you use, your Device unique ID, the IP address of your Device, your operating system, the type of Internet browser you use, unique Device identifiers and other diagnostic data.</p>
           
                   <p class="my-5">    <b>Tracking Cookies Data</b></p>
           
                   <p>    We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information. Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your Device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyze our Service. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service. Examples of Cookies we use:</p>
                           <ul class="ml-10">
                               <li>Session Cookies: We use Session Cookies to operate our Service,</li>
                               <li>Preference Cookies: We use Preference Cookies to remember your preferences and various settings,</li>
                               <li>Security Cookies: We use Security Cookies for security purposes,</li>
                               <li>Advertising Cookies: Advertising Cookies are used to serve you with advertisements that may be relevant to you and your interests.</li>
                           </ul>
                   <p class="my-5">    6. Use of Data</p>
                   <p>    Exie App LLC uses the collected Personal Data for various purposes:</p>
                       <ul class="ml-10">
                           <li>    to provide and maintain our Service; type of Personal Data: email address, first name and last name, Cookies and Usage Data; necessity for the performance of a contract to which you are a party;</li>
                           <li>    to notify you about changes to our Service; type of Personal Data: email address, first name and last name, Cookies and Usage Data; necessity for the performance of a contract to which you are a party;</li>
                           <li>    to allow you to participate in interactive features of our Service when you choose to do so; type of Personal Data: email address, first name and last name, Cookies and Usage Data; necessity for the performance of a contract to which you are a party;</li>
                           <li>    to provide customer support; type of Personal Data: email address, first name and last name, Cookies and Usage Data; necessity for the performance of a contract to which you are a party;</li>
                           <li>    to gather analysis or valuable information so that we can improve our Service; type of Personal Data: email address, first name and last name, Cookies and Usage Data; legitimate interests of the Data Controller;</li>
                           <li>    to monitor the usage of our Service; type of Personal Data: email address, first name and last name, Cookies and Usage Data; legitimate interests of the Data Controller;</li>
                           <li>    to detect, prevent and address technical issues; type of Personal Data: email address, first name and last name, Cookies and Usage Data; legitimate interests of the Data Controller;</li>
                           <li>    to fulfill any other purpose for which you provide it; type of Personal Data: email address, first name and last name, Cookies and Usage Data; necessity for the performance of a contract to which you are a party;</li>
                           <li>    to carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection; type of Personal Data: email address, first name and last name, Cookies and Usage Data; necessity for the performance of a contract to which you are a party;</li>
                           <li>    to provide you with notices about your account and/or subscription, including expiration and renewal notices, email-instructions, etc.; type of Personal Data: email address, first name and last name, Cookies and Usage Data; necessity for the performance of a contract to which you are a party;</li>
                           <li>    to provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information; type of Personal Data: email address, first name and last name, Cookies and Usage Data; upon your consent;</li>
                           <li>    in any other way we may describe when you provide the information; type of Personal Data: email address, first name and last name, Cookies and Usage Data; necessity for the performance of a contract to which you are a party;</li>
                       </ul>
           
                   <p class="my-5">    7. Retention of Data</p>
                   <p>    We will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies. Your Personal Data processed upon your consent will be stored for as long as the relevant consent is not withdrawn and until the expiration of claims resulting from the Service. We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.</p>
           
                   <p class="my-5">    8. Transfer of Data</p>
                   <p>    Your information, including Personal Data, may be transferred to – and maintained on – computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction. If you are located outside United States and choose to provide information to us, please note that we transfer the data, including Personal Data, to United States and process it there. The Company will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information. When we transfer your Personal Data to other countries, we will protect that Personal Data as described in this Privacy Policy and in accordance with applicable law. We use contractual protections for the transfer of Personal Data among various jurisdictions (the European Commission’s standard contractual clauses referred to in Article 46. 2 c) of the GDPR).</p>
           
                   <p class="my-5">    9. Disclosure of Data</p>
                   <p>    We may disclose Personal Data you provide:</p>
                       <ul class="ml-10">
                           <li>   under certain circumstances, if required to do so by law or in response to valid requests by public authorities;</li>
                           <li>   if we or our subsidiaries are involved in a merger, acquisition or asset sale;</li>
                           <li>   to our subsidiaries, affiliates, employees;</li>
                           <li>   to contractors, service providers, and other third parties we use to support our business;</li>
                           <li>   to fulfill the purpose for which you provide it;</li>
                           <li>   for the purpose of including your company’s logo on our Website;</li>
                           <li>   with your consent in any other cases.</li>
                       </ul>
                    
                    <p>   We do not sell or otherwise share your Personal Data, except as described in this Privacy Policy.</p>
           
                    <p class="my-5">   10. Security of Data</p>
                    <p>   The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. We use appropriate administrative, technical and physical safeguards to protect the Personal Data you provide against accidental, unlawful or unauthorized destruction, loss, alteration, access, disclosure or use, e.g. we maintain backup copies and only authorized personnel may access the Personal Data.</p>
           
                   <p class="my-5">   11. Your Data Protection Rights Under General Data Protection Regulation (GDPR)</p>
                    <p>   If you are a resident of the European Union (EU) and European Economic Area (EEA), you have certain data protection rights, covered by GDPR. We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data. You have the following data protection rights:</p>
                       <ul class="ml-10">
                           <li>   to access to your Personal Data by requesting sharing and/or sending a copy of all your Personal Data processed by us;</li>
                           <li>   to request rectification of inaccurate Personal Data by indicating the data requiring rectification;</li>
                           <li>   to request erasure of your Personal Data; we have the right to refuse to erase the Personal Data in specific circumstances provided by law;</li>
                           <li>   to request restriction of processing of your Personal Data by indicating the data which should be restricted;</li>
                           <li>   to object to processing your Personal Data conducted based on grounds relating to your particular situation;</li>
                           <li>   to withdraw the consent to process your Personal Data at any time. Withdrawal of the consent is possible solely in the scope of processing made based on consent. We are authorized to process your Personal Data after withdrawal your consent if we have the legal basis for such processing, for the purposes defined by that legal basis;</li>
                           <li>   to lodge a complaint with a supervisory authority, in particular in the EU member state of your habitual residence, place of work or place of the alleged infringement if you consider that the processing of Personal Data relating to you infringes GDPR.</li>
                       </ul>
                    <p>   If you wish to execute any of the above-mentioned rights, please email us at support@exie.app. Please note that we may ask you to verify your identity before responding to such requests. Please note, we may not able to provide Service without some necessary data.</p>
           
                    <p class="my-5">   12. Your Data Protection Rights under the California Privacy Protection Act (CalOPPA)</p>
                    <p>   CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy policy. The law’s reach stretches well beyond California to require a person or company in the United States (and conceivable the world) that operates websites collecting personally identifiable information from California consumers to post a conspicuous privacy policy on its website stating exactly the information being collected and those individuals with whom it is being shared, and to comply with this policy. See more at: https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/.</p>
           
                   <p>    According to CalOPPA we agree to the following:</p>
                       <ul>
                           <li>    users can visit our site anonymously;</li>
                           <li>    our Privacy Policy link includes the word “Privacy”, and can easily be found on the page specified above on the home page of our website;</li>
                           <li>    users will be notified of any privacy policy changes on our Privacy Policy Page;</li>
                           <li>    users are able to change their personal information by emailing us at support@exie.app.</li>
                       </ul>
                   <p>    Our Policy on “Do Not Track” Signals:</p>
           
                   <p>    We honor Do Not Track signals and do not track, plant cookies, or use advertising when a Do Not Track browser mechanism is in place. Do Not Track is a preference you can set in your web browser to inform websites that you do not want to be tracked. You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.</p>
           
                   <p class="my-5">    13. Service Providers</p>
                   <p>    We may employ third party companies and individuals to facilitate our Service (“Service Providers”), provide Service on our behalf, perform Service-related services or assist us in analysing how our Service is used. These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
           
                   <p class="my-5">    14. Analytics</p>
                   <p>    We may use third-party Service Providers to monitor and analyze the use of our Service.</p>
           
                   <p>    <b>Google Analytics</b></p>
           
                    <p>   Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualise and personalise the ads of its own advertising network. For more information on the privacy practices of Google, please visit the Google Privacy Terms web page: https://policies.google.com/privacy?hl=en. We also encourage you to review the Google's policy for safeguarding your data: https://support.google.com/analytics/answer/6004245.</p>
           
                   <p class="my-5">    15. Payments</p>
                   <p>    We may provide paid products and/or services within Service. In that case, we use third-party services for payment processing (e.g. payment processors). We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information. The payment processors we work with are:</p>
           
                   <p>    Stripe - Privacy Policy of Stripe Inc. can be viewed at: https://stripe.com/us/privacy.</p>
                   <p class="my-5">    16. Links to Other Sites</p>
                   <p>    Our Service may contain links to other sites that are not operated by us. If you click a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit. We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
           
                   <p class="my-5">    17. Children's Privacy</p>
                   <p>    Our Service does not address anyone under the age of 16 (“Children”). We do not knowingly collect personally identifiable information from anyone under the age of 16. If you are a parent or guardian and you are aware that your Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.</p>
           
                   <p class="my-5">    18. Changes to This Privacy Policy</p>
                   <p>    We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating “effective date” at the top of this page, unless another type of notice is required by the applicable law. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page. By continuing to use our Service or providing us with Personal Data after we have posted an updated Privacy Policy, or notified you if applicable, you consent to the revised Privacy Policy and practices described in it.</p>
           
                   <p class="my-5">    19. Contact Us</p>
                   <p>    If you have any questions about this Privacy Policy, please contact us at support@exie.app.</p>
               </v-sheet>
            </v-container>
            <Footer />
        </v-main>
    </v-layout>
</template>

<script>
import UnauthorizedAppBar from '../components/UnauthorizedAppBar.vue'
import Footer from '@/components/Footer.vue'
export default {
    components: {UnauthorizedAppBar,Footer}
}
</script>