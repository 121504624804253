<template>
    
</template>

<script>
import confetti from 'canvas-confetti'
import {ref, onMounted} from "vue"
export default {
    setup() {
        const confettiRef = ref()

        onMounted(() => {
            confetti({
                spread: 120,
                angle: -30,
                particleCount: 100,
                gravity: 0.5,
                startVelocity: 55,
                zIndex: 2800,
                origin: {
                    x: 0,
                    y: 0
                }
            })
            confetti({
                spread: 120,
                angle: 210,
                particleCount: 100,
                gravity: 0.5,
                startVelocity: 55,
                zIndex: 2800,
                origin: {
                    x: 1,
                    y: 0
                }
            })
            confetti({
                spread: 120,
                angle: 90,
                particleCount: 100,
                gravity: 0.5,
                startVelocity: 55,
                zIndex: 2800,
                origin: {
                    x: 0.5,
                    y: 1
                }
            })
        })

        return { confettiRef }
    }
}
</script>

<style scoped>
#confetti {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2800;
}
</style>