<template>
    <v-layout class="h-100">
        <UnauthorizedAppBar />
        <v-main class="h-100">
            <div class="h-100 d-flex flex-column">
                <v-container class="pa-5 mb-auto">
                    <v-row>
                        <v-col cols="12" class="d-flex justify-center">
                            <div class="pa-7" :style="{'max-width': '900px'}">
                                <p class="text-hero mt-1 text-uppercase">Looking for a job? </p> 
                                <p class="text-hero mt-1 text-uppercase">Struggling to get an offer? </p> 
                                <p class="text-hero mt-1 text-uppercase">Feeling nervous during interviews? </p> 
                                <p class="text-hero-description mt-7 font-weight-medium text-medium-emphasis" :style="{'max-width': '507px'}">
                                    Take a FREE mock interview with an AI interviewer and get personalized preparation tips!
                                </p>
                                <div class="mt-8 text-center" :style="{display: 'inline-block'}">
                                    <v-btn color="black" block class="rounded-16" varinat="flat" size="large" @click="handleLogin">
                                        Login with Google
                                    </v-btn>
                                    <p class="text-caption mt-3">
                                        By signing up, you agree to Mockin <br />
                                        <router-link to="/tos" class="text-decoration-none">Terms of Service</router-link> and <router-link to="/privacy-policy" class="text-decoration-none">Privacy Policy</router-link>
                                    </p>
                                </div>
                                
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
                <div>
                    <Footer />
                </div>
            </div>
        </v-main>
    </v-layout>
</template>

<script>
import api from '@/utils/api'
import { useAppStateStore } from '@/stores/AppStateStore'
import {useRouter} from 'vue-router'
import Footer from '@/components/Footer.vue'
import UnauthorizedAppBar from '@/components/UnauthorizedAppBar.vue'
export default {
    components: {UnauthorizedAppBar, Footer},
    setup() {
        
        const router = useRouter()
        const appStateStore = useAppStateStore()
        if(appStateStore.user) {
            router.push({name: 'dashboard'})
        }
        
        const handleLogin = async () => {
            const reposne = await api.get('/api/gauth/login')

            window.location.href = reposne.redirect
        }

        return {handleLogin}
    }
}
</script>

<style>
.text-hero {
    font-size: 48px;
    font-weight: 400;
    line-height: 56px;
}

.text-hero-description {
    font-size: 23px;

}
</style>