<template>
    <v-dialog
        persistent
        v-model="showModal"
        max-width="701px"
    >
        <v-card rounded="xl" class="py-8 px-5 d-flex flex-column align-center">
            <div class="d-flex flex-column align-center justify-center">
                <p class="exie-text-h4 text-center">Welcome to Mockin</p>
                <p class="exie-text-body-1 text-medium-emphasis text-center mt-2" :style="{maxWidth: '342px'}">
                    I’m your personal hiring manager. Before you start please introduce yourself
                </p>
            </div>
            <div class="mt-5 px-3" :style="{maxWidth: '541px', width: '100%'}">
                <p class="exie-text-subtitle-1 font-weight-bold">Your grade</p>
                <v-select 
                    v-model="gradeRef"
                    class="mt-2"
                    rounded="lg"
                    flat
                    block
                    variant="solo-filled"
                    placeholder="Select Role"
                    :items="userGrades"
                    hide-details
                    >
                </v-select>
                <p class="exie-text-subtitle-1 font-weight-bold mt-5">Your goal</p>
                <v-select 
                    v-model="goalRef"
                    class="mt-2"
                    rounded="lg"
                    flat
                    block
                    variant="solo-filled"
                    :items="userGoals"
                    placeholder="Select Goal"
                    hide-details
                    >
                </v-select>
            </div>
            <div class="d-flex justify-center align-center mt-8 mb-3">
                <v-btn 
                    color="black"
                    flat
                    class="rounded-16"
                    :disabled="!gradeRef || !goalRef"
                    @click="handleSaveQuestionaire"
                >
                    Let’s start!
                </v-btn>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import {ref, onMounted} from "vue"
import { userGrades, userGoals } from "@/utils/userQuestionaire"
import { useCookies } from "vue3-cookies"
import { useAppStateStore } from "@/stores/AppStateStore"
import { storeToRefs } from "pinia"
import api from "@/utils/api"
export default {
    setup() {
        const showModal = ref(false)

        const appStateStore = useAppStateStore()
        const { user } = storeToRefs(appStateStore)

        const { cookies } = useCookies()
        const cookieConsent = JSON.parse(cookies.get('consent'))

        onMounted(() => {
            if(user.value) {
                showModal.value = cookieConsent && (!user.value.q_grade || !user.value.q_goal)
            }
        })
        
        const handleSaveQuestionaire = async () => {
            if(!gradeRef.value || !goalRef.value) {
                return
            }
            const response = await api.post('/api/init/questionaire', {
                grade: gradeRef.value,
                goal: goalRef.value
            })
            
            if(response.user) {
                appStateStore.updateUser(response.user)
                showModal.value = false
            }
        }

        const gradeRef = ref(null)
        const goalRef = ref(null)

        return {
            showModal,
            gradeRef,
            goalRef,
            userGrades,
            userGoals,
            handleSaveQuestionaire
        }
    }
}
</script>