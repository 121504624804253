import { defineStore } from 'pinia'
import mixpanel from 'mixpanel-browser'

export const useMixPanelStore = defineStore('mixPanelStore', {
    state: () => ({ 
        inited: false
    }),
    actions: {
        init() {
            if(process.env.VUE_APP_ENV != 'PRODUCTION') {
                return
            }

            if(!this.inited) {
                mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN, {
                    debug: false,
                    loaded: () => {
                        this.inited = true
                    }
                })
            }
        },
        setUser(user) {
            if(process.env.VUE_APP_ENV != 'PRODUCTION') {
                return
            }
            if(this.inited) {
                mixpanel.identify(user.id)
                mixpanel.people.set({
                    '$name': user?.name,
                    '$email': user?.email,
                })
            }
        },
        unsetUser() {
            if(process.env.VUE_APP_ENV != 'PRODUCTION') {
                return
            }
            if(this.inited) {
                mixpanel.reset()
            }
        },
        track(event, data) {
            if(process.env.VUE_APP_ENV != 'PRODUCTION') {
                return
            }
            if(this.inited) {
                console.log('mixpanel track', event, data)
                mixpanel.track(event, data)
            }
        }
    }
})