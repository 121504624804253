<template>
    <v-layout class="h-100">
        <Websocket v-if="user" />
        <v-app-bar elevation="0">
          <v-app-bar-title>
            <v-img
                      max-height="30px"
                      max-width="100px"
                      aspect-ratio="16/9"
                      position="left"
                      class="cursor-pointer"
                      src="/images/appbar-logo.svg"
                      @click="handleGoHome"
                    />
          </v-app-bar-title>
          <v-app-bar-nav-icon v-if="!mdAndUp" variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
          <span class="text-caption text-medium-emphasis mr-8">Beta 1.1.1</span>
        </v-app-bar>
        <v-navigation-drawer v-model="drawer" mobile-breakpoint="md" class="pa-1 pl-3 border-e-0">
          <div class="d-flex flex-column pt-5 h-100">
            <Sidebar />
          </div>
        </v-navigation-drawer>
        <v-main class="h-100">
            <v-sheet class="d-flex flex-column h-100">
                <v-container class="mb-auto" :style="{'max-width': '960px'}">
                    <router-view/>
                </v-container>
                <div>
                    <Footer />
                </div>
                <GradeAndGoalModal />
            </v-sheet>
        </v-main>
    </v-layout>
    
    
</template>

<script>
import { useAppStateStore } from '@/stores/AppStateStore'
import { useRouter } from 'vue-router'
import { ref, watch } from 'vue'
import { useDisplay } from 'vuetify'
import Sidebar from '@/components/Sidebar.vue'
import { storeToRefs } from 'pinia'
import Websocket from '@/components/ws/Websocket.vue'
import Footer from '@/components/Footer.vue'
import GradeAndGoalModal from '@/components/Questionaire/GradeAndGoalModal.vue'

export default {
    components: {Sidebar, Websocket, Footer, GradeAndGoalModal},
    setup() {
        const appStateStore = useAppStateStore()
        const router = useRouter()

        const { user } = storeToRefs(appStateStore)

        const {mdAndUp} = useDisplay()
        const drawer = ref(mdAndUp.value ? true: false)

        const redirect = () => {
            if(!user.value) {
                window.location.replace('https://mockin.work')
            }
        }

        redirect()

        watch(user, () => {
            redirect()
        })

        const handleGoHome = () => {
            router.push('/')
        }

        return {
            mdAndUp,
            drawer,
            handleGoHome,
            user
        }
    }
}
</script>