<template>
    History

    <div>
        <v-data-table :items="interviews" :headers="headers">
            <template v-slot:item.type="{ item }">
                <v-chip class="cursor-pointer" @click="handleGoInterview(item.id)">
                    {{ getInterviewTitle(item.type) }} ({{ item.language ? getLanguageTitle(item.language): '' }})
                </v-chip>
            </template>
            <template v-slot:item.status="{ item }">
                {{ item.status == 1 ? 'Active' : 'Finished' }}
            </template>
            <template v-slot:item.ts_created="{ item }">
                {{ getFullDateFromString(item.ts_created) }}
            </template>
        </v-data-table>
    </div>
</template>

<script>
import api from '@/utils/api'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { getInterviewTitle, getLanguageTitle } from '@/utils/interviews'
import { getFullDateFromString } from '@/utils/dataFormatter'

export default {
    setup() {
        const router = useRouter()
        const interviews = ref([])
        const headers = [
            { title: 'Interview', key: 'type'}, 
            { title: 'Status', key: 'status'},
            { title: 'Started at', key: 'ts_created'}
        ]

        const getInterviews = async () => {
            const response = await api.get('/api/interview/list')

            if(response.interviews) {
                interviews.value = response.interviews
            }
        }

        const handleGoInterview = async (id) => {
            router.push({name: 'interview', params: {id: id}})
        }

        getInterviews()

        return {
            interviews,
            headers,
            handleGoInterview,
            getInterviewTitle,
            getFullDateFromString,
            getLanguageTitle
        }
    }
}
</script>