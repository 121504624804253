<template>
    <div class="mb-3"></div>
    <v-row>
        <v-col cols="12" sm="8" class="pa-2">
            <p class="exie-text-h4">Your CV</p>
        </v-col>
        <v-col cols="12" sm="8">
            <v-file-input ref="cvUploaderRef" v-model="cvFile" :rules="cvValidationRules" accept="application/pdf" show-size="1024" clearable label="Upload your CV" variant="solo"></v-file-input>
            <v-btn @click="handleUploadCv" :disabled="!cvFile || !cvUploaderRef?.isValid" class="rounded-16" color="black" variant="outlined" size="large">Upload</v-btn>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" sm="8" class="pa-2">
            <div>
                My CV url link: <a v-if="cvData" :href="cvData.link" target="_blank">CV</a>
            </div>
            <v-btn @click="handleGetCvRecommendations" class="rounded-16 my-5" color="black" variant="flat" size="large">Give me recommendations</v-btn>
            <div class="cv_recommendations">
                <vue-markdown v-if="cvRecommendations" :source="cvRecommendations"></vue-markdown>
                <v-progress-circular v-if="!cvRecommendations && cvRecommendationsLoading" indeterminate size="128" color="black"></v-progress-circular>
            </div>
        </v-col>
    </v-row>
</template>
<script>
import {ref} from 'vue'
import api from '@/utils/api'
import VueMarkdown from 'vue-markdown-render'
import {useAppStateStore} from '@/stores/AppStateStore'
import {storeToRefs} from 'pinia'
import {useRouter} from 'vue-router'
export default {
    components: {VueMarkdown},
    setup() {
        const cvValidationRules = [
            (value) => {
                return !value || !value.length || value[0].size < 3 * 1024 * 1024 || 'CV size should be less than 3 MB!'
            }
        ]
        const router = useRouter()
        const appStateStore = useAppStateStore()
        const { user } = storeToRefs(appStateStore)

        const checkUser = () => {
            if(![1, 4, 5, 13, 282].includes(user.value.id)) {
                router.push({'name': 'dashboard'})
            }
        }
        checkUser()

        const cvFile = ref(null)
        const cvUploaderRef = ref()

        const cvData = ref(null)

        const cvRecommendations = ref(null)
        const cvRecommendationsLoading = ref(false)

        const handleUploadCv = async () => {
            if (!cvFile.value || !cvUploaderRef.value.isValid) {
                return
            }

            const blob = new Blob([cvFile.value], {type: 'application/pdf'})

            const response = await fetch('/api/cv/upload', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/pdf'
                },
                body: blob
            })

            const json = await response.json()
            cvData.value = json.user_cv
            cvFile.value = null
        }

        const handleGetCvRecommendations = async () => {
            cvRecommendationsLoading.value = true
            const response = await api.get('/api/cv/recommendations')
            cvRecommendationsLoading.value = false
            cvRecommendations.value = response.recommendations
        }

        const getCv = async () => {
            const response = await api.get('/api/cv/get')
            cvData.value = response.user_cv
        }

        getCv()

        return {
            cvValidationRules,
            cvFile,
            cvUploaderRef,
            handleUploadCv,
            cvData,
            cvRecommendations,
            handleGetCvRecommendations,
            cvRecommendationsLoading
        }
    }
}
</script>

<style scoped>
.cv_recommendations :deep(p) {
    margin-top: 12px;
    margin-bottom: 24px;
}
.cv_recommendations :deep(ul) {
    margin-left: 24px;
    margin-bottom: 24px;
}
.cv_recommendations :deep(ol) {
    margin-left: 24px;
    margin-bottom: 24px;
}
.cv_recommendations :deep(hr) {
    margin-top: 24px;
    margin-bottom: 24px;
}
</style>