<template>
    <div class="mb-3"></div>
    <v-row>
        <v-col cols="12" sm="8" md="5" class="pa-2">
            <p class="exie-text-h4">Help Center</p>
            <p class="exie-text-body-1 mt-8">
                We appreciate that you have chosen our product.
                If you have questions, comments or wishes, we will be happy to help you.
            </p>
            <p class="exie-text-body-1 mt-8">
                Just email us: <span class="text-black">support@mockin.work</span>
            </p>
            <p class="exie-text-body-1 mt-8">
                Also, you can join to our community in <a href="https://t.me/+MRmcwZNJgXk0MWNi" target="_blank" class="text-decoration-none">Telegram</a>
            </p>
            <p class="exie-text-body-1 mt-8">
                Your Mockin team <span class="text-black">🖤</span>
            </p>
        </v-col>
    </v-row>
</template>