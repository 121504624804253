const userGrades = [
    {
        title: 'Student',
        value: 'student'
    },
    {
        title: 'Intern',
        value: 'intern'
    },
    {
        title: 'Junior',
        value: 'junior'
    },
    {
        title: 'Middle',
        value: 'middle'
    },
    {
        title: 'Senior',
        value: 'senior'
    },
    {
        title: 'Lead',
        value: 'lead'
    },
    {
        title: 'Principal Designer',
        value: 'principal'
    },
    {
        title: 'Design Manager',
        value: 'manager'
    },
    {
        title: 'Head of Design',
        value: 'head'
    }
]

const userGoals = [
    {
        title: 'Find a job',
        value: 'find_a_job',
    },
    {
        title: 'Further training/education',
        value: 'training',
    },
    {
        title: 'Improve skills/gain experience',
        value: 'scoring',
    },
    {
        title: 'Explore product design as a career',
        value: 'want_to_be',
    },
    {
        title: 'General interest/personal enrichment',
        value: 'interesting'
    }
]

export { userGrades, userGoals }