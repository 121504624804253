<template>
    <div v-if="user" class="d-flex flex-column align-center mb-2">
        <v-avatar :image="user.photo_url" size="80"></v-avatar>
        <p class="exie-text-subtitle-2">{{ user.name }}</p>
        <p class="text-caption text-medium-emphasis">{{ user.email }}</p>
    </div>
    <div class="sidebar_menu mb-auto">
        <v-list nav density="compact" class="text-left mb-auto" :selected="currentTab">
            <v-list-item :prepend-icon="mdiViewDashboard" title="Dashboard" value="dashboard" :to="{name: 'dashboard'}" />
            <v-list-item :prepend-icon="mdiHistory" title="History" value="history" :to="{name: 'history'}" />
        </v-list>
    </div>
    <div class="sidebar_menu">
        <v-list nav density="compact" class="text-left" :selected="currentTab">
            <v-list-item class="cursor-pointer" :prepend-icon="mdiHelpCircleOutline" title="Help" value="help" :to="{name: 'help'}" />
            <v-list-item class="cursor-pointer" :prepend-icon="mdiLogout" title="Logout" @click="handleLogout" />
        </v-list>
    </div>
</template>

<script>
import { mdiViewDashboard, mdiLogout, mdiHistory, mdiHelpCircleOutline, mdiFileAccountOutline } from '@mdi/js'
import { useAppStateStore } from '@/stores/AppStateStore'
import { storeToRefs } from 'pinia'
import { useRoute, useRouter } from 'vue-router'
import { ref } from 'vue'
import api from '@/utils/api'

export default {
    setup() {
        const route = useRoute()
        const router = useRouter()
        const appStateStore = useAppStateStore()
        const { user } = storeToRefs(appStateStore)

        const currentTab = ref(null)
        currentTab.value = route.meta.navbar

        router.afterEach((to, from) => {
            currentTab.value = route?.meta?.navbar
        })

        const handleLogout = async () => {
            const response = await api.post('/api/gauth/logout')
            appStateStore.logout()
        }

        return {user, mdiViewDashboard, mdiLogout, mdiHistory, currentTab, handleLogout, mdiHelpCircleOutline, mdiFileAccountOutline}
    }
}
</script>

<style scoped>
.sidebar_menu :deep(.v-list-item__overlay) {
    border-radius: 8px;
}
.sidebar_menu :deep(.v-list-item-title) {
    font-size: 15px;
}
.sidebar_menu :deep(.v-list-item__spacer) {
    width: 12px !important;
}
</style>